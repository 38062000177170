<template>
  <div>
    <p class="unsubscribe_phones">
      <span @click="phone='iphone'">iPhone</span>
      <span @click="phone='android'">Android</span>
    </p>
    <div v-if="lang === 'en'">
      <p @click="lang='ru'" class="unsubscribe_lang_link">Перейти на русский язык</p>
      <div v-if="phone === 'iphone'">
        <h1>Disabling the calendar on iPhone</h1>
        <ol>
          <li>Go to <b>settings</b>.</li>
          <li>Go to the <b>Passwords and accounts</b> section.</li>
          <li>Select <b>Subscribed Calendars</b>.</li>
          <li>Click on the calendar you want to delete.</li>
          <li>At the bottom of the window, click <b>Delete account</b>.</li>
          <li>Then <b>confirm</b> the deletion.</li>
        </ol>
        <p>The calendar will be deleted from the device.</p>
      </div>
      <div v-if="phone === 'android'">
        <h1>Disabling the calendar on Android</h1>
        <ol>
          <li>
            <div>Go to the calendar from your phone using <a
                href="https://calendar.google.com/calendar/render?hl=ru">the link</a> in Google
              Chrome or another browser.
            </div>
            </li>
          <li>
            <div>Select the calendar to delete in the My calendars section.</div>
            </li>
          <li>
            <div>Click the X icon next to the calendar's name, then click Delete from the
              list.
            </div>
            </li>
          <li>
            <div>Go to the device settings.</div>
            </li>
          <li>
            <div>Proceed to Google section &gt; Services in your account.</div>
            </li>
          <li>
            <div>Proceed to Connected Apps section &gt; Event.Me.</div>
            </li>
          <li>
            <div>Click Disable and confirm disconnection.</div>
            </li>
        </ol>
        The calendar will be deleted from your device.
      </div>
    </div>
    <div v-if="lang === 'ru'">
      <p @click="lang='en'" class="unsubscribe_lang_link">Switch to English</p>
      <div v-if="phone === 'iphone'">
        <h1>Отключение календаря на iPhone</h1>
        <ol>
          <li>Перейдите в <b>Настройки</b>.<p></p></li>
          <li>Перейдите в раздел <b>Пароли и учетные записи</b>.<p></p></li>
          <li>Выберите <b>Подписные календари</b>.<p></p></li>
          <li>Нажмите на календарь, который нужно удалить.<p></p></li>
          <li>В нижней части окна нажмите <b>Удалить учетную запись</b>.<p></p></li>
          <li>Повторно нажмите <b>Удалить учетную запись</b>, чтобы подтвердить удаление.<p>
          </p></li>
        </ol>
        <p>Календарь будет удален с устройства.</p></div>
      <div v-if="phone === 'android'">
        <h1>Отключение календаря на Android</h1>
        <ol>
          <li>
            <div>Перейдите по ссылке в <a
                href="https://calendar.google.com/calendar/render?hl=ru">ваш календарь</a>
              через Google Chrome или другой браузер.
            </div>
            </li>
          <li>
            <div>Выберите календарь, который нужно удалить, в разделе Мои календари.</div>
            </li>
          <li>
            <div>Нажмите на кнопку X рядом с названием календаря, затем кнопку Удалить из
              списка.
            </div>
            </li>
          <li>
            <div>Зайдите в настройки устройства.</div>
            </li>
          <li>
            <div>Перейдите в раздел Google &gt; Сервисы в аккаунте.</div>
            </li>
          <li>
            <div>Перейдите в раздел Подключенные приложения &gt; Event.Me.</div>
            </li>
          <li>
            <div>Нажмите кнопку Отключить и подтвердите отключение.</div>
            </li>
        </ol>
        Календарь будет удален с устройства.
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Unsubscribe',
  data() {
    return {
      lang: 'en',
      phone: 'iphone'
    }
  }
}
</script>

<style lang="scss">
body {
  padding: 20px;
}
.unsubscribe_lang_link, .unsubscribe_phones span {
  cursor: pointer;
  text-decoration: underline;
}
.unsubscribe_phones span {
  margin-right: 20px;
}
</style>
